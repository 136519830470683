import {useState} from "react";
import * as React from "react";

const ImageZoom = ({images, alt= ""}) => {
    const [isFullscreen, setFullscreen] = useState(-1);

    function handleKeyPressed(event) {
        console.log(event)
        if (event.key === "ArrowRight")  {
            if (isFullscreen < images.length-1) setFullscreen(isFullscreen + 1)
            else setFullscreen(-1)
        }
        if (event.key === "ArrowLeft")  {
            if (isFullscreen > 0) setFullscreen(isFullscreen - 1)
            else setFullscreen(-1)
        }
    }

    return (
        <div>
         { isFullscreen !== -1 &&
             <div style={{ backgroundImage: `url(${images[isFullscreen].publicURL})` }}
                  role="button" tabIndex="0"
                  onKeyDown={() => handleKeyPressed()}
                  onClick={() => setFullscreen(-1)}
                  className="transition-all duration-1000 bg-contain bg-center bg-no-repeat fixed w-full h-screen z-20 top-0 left-0 bg-black"> </div>
         }
        { images.map( (src,index) =>
                <div key={src.publicURL} style={{ backgroundImage: `url(${src.publicURL})` }} className="mt-10 transition-all duration-1000 aspect-[4/3] bg-contain bg-no-repeat w-full left-0 top-0"
                     role="button" tabIndex="0" aria-label="Image fullscreen"
                     onClick={() => setFullscreen(index)} onKeyDown={() => setFullscreen(index)}/>
            ) }
        </div>
)

}
export default ImageZoom
