import React from 'react'
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

const GatsbyImageLink = ({ image, alt }) => {
    return(
        <Link to={"/fullimages/" + image.childImageSharp.id}>
            <GatsbyImage className="m-1" alt={alt} image={getImage(image)} />
        </Link>
    )
}

export default GatsbyImageLink

