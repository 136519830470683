import React from 'react'
import Highlight, {defaultProps} from 'prism-react-renderer'

import Prism from "prism-react-renderer/prism";

(typeof global !== "undefined" ? global : window).Prism = Prism;

require("prismjs/components/prism-kotlin");
require("prismjs/components/prism-csharp");
require("prismjs/components/prism-basic");
require("prismjs/components/prism-asm6502");
require("prismjs/components/prism-java");
require("prismjs/components/prism-ruby");

const CodeBlock = ({ children, className, metastring }) => {
    const language = className ? className.replace(/language-/, '') : '' || ""
    return (
        <Highlight {...defaultProps} code={children} language={language}
                   theme={undefined}>
            {({className, style, tokens, getLineProps, getTokenProps}) => (
                <pre className={className+ " " + metastring} style={{...style, padding: '20px'}}>
          {tokens.map((line, i) => (
              <div key={i} {...getLineProps({line, key: i})}>
                  {line.map((token, key) => (
                      <span key={key} {...getTokenProps({token, key})} />
                  ))}
              </div>
          ))}
        </pre>
            )}
        </Highlight>
    )
}

export default CodeBlock
