import * as React from 'react'
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {useState} from "react";
import CodeBlock from "./CodeBlock";

const CodeTabs = ({index = 0, children}) => {
    const [tabIndex, setTabIndex] = useState(index);

    return (
        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
            <TabList>
                {React.Children.map(children, (child, index) =>
                    <Tab>{child.props.lang}</Tab>
                )}
            </TabList>
            {React.Children.map(children, (child, index) =>
                    <TabPanel>
                        <CodeBlock children={child.props.code} className={ "language-" + child.props.lang} metastring=""/>
                    </TabPanel>
            )}
        </Tabs>
    )
}
export default CodeTabs
